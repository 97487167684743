@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductGridTextImageContainer {
  @include position-100(fixed);
  @include z-index(product-grid-text-image);
  @include flex-center;
  pointer-events: none;
}

.imageContainer {
  width: px(360);
  aspect-ratio: 358/477;
  position: relative;
}

.image {
  @include position-100(absolute);
  object-fit: cover;
  opacity: 1 !important;

  &.isActive {
    z-index: 10;
  }
}
