@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ContentMask {
  position: relative;
  overflow: hidden;
  display: block;
}

.inner {
  width: 100%;
  display: block;

  [data-start-pos='UP'] & {
    transform: translateY(-105%);
  }

  [data-start-pos='DOWN'] & {
    transform: translateY(105%);
  }
}
