@import "@/styles/shared";
.container {
  position: relative;
}

.spacer {
  min-width: px(10);
  min-height: px(10);
}

.ThemeToggle {
  @include reset-button;
  @include transform-center;
  position: absolute;
  padding: px(10);
}

.dot {
  min-width: px(10);
  min-height: px(10);
  border-radius: 50%;
  background-color: var(--fg);
}
