@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.LogoWithProducts {
  padding: 0 var(--page-gutter);
}

.logoContainer {
  opacity: 0.001;

  &.isVisible {
    opacity: 1;
  }

  svg {
    display: block;
  }
}

.productsContainer {
  width: 100%;
  display: flex;
  row-gap: px(33);
  margin-top: px(27);
  flex-direction: column;

  @include bp(tablet) {
    row-gap: px(137);
    margin-top: px(57);
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}
