@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductZoomView {
  @include position-100(fixed);
  @include z-index(zoom-view);
  opacity: 1;
  background-color: var(--bg-invert);
  clip-path: polygon(0% 0%, 100% 0%, 100% var(--left-y), 0% var(--right-y));

  --left-y: 0%;
  --right-y: 0%;
}

.close {
  @include reset-button;
  @include font-h5;
  position: absolute;
  top: px(21);
  right: var(--page-gutter);
  mix-blend-mode: difference;
  z-index: 5;
  color: $white;
}

.leftSide,
.rightSide {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
}

.leftSide {
  left: 0;
}

.rightSide {
  margin: 0;
  left: 50%;
  overflow: hidden;
}

.img {
  @include position-100(absolute);
  object-fit: cover;
}

.zoomImageContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  aspect-ratio: var(--window-aspect);
  transform: translate(-50%, -50%);
  width: 150vw;
}

.zoomImageContainer__inner {
  @include position-100(absolute);
}

.zoomImageContainer__img {
  @include position-100(absolute);
  object-fit: cover;
}

.box {
  border: 1px solid $black;
  position: absolute;
  top: 0;
  left: 0;
}

.hoverBox {
  outline: 1px solid white;
  mix-blend-mode: difference;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;

  .leftSide:hover & {
    opacity: 1;
  }
}
