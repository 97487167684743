@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.NextProduct {
  position: fixed;
  min-height: 50svh;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--bg-invert);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.link {
  height: var(--next-item-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: var(--page-gutter);

  @include hover {
    .imageContainer {
      opacity: 0.5;
    }
  }
}

.textLeft,
.textRight {
  @include font-h3;
  color: var(--fg-invert);
}

.imageContainer {
  width: px(70);
  height: px(95);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s;

  @include bp(tablet) {
    width: px(180);
    height: px(246);
  }
}

.productImage {
  @include position-100(absolute);
  object-fit: cover;
}
