@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MonoSlashModelText {
  @include flex-center;
}

.index {
  @include font-body-mono;
  opacity: 0.5;
}

.indexSlash {
  @include font-body-5;
}

.model {
  @include font-body-5;
}
