@import "@/styles/shared";
.NavigationDrawer {
  @include z-index(navigation-drawer);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &.navigationIsOpen {
    pointer-events: all;
  }
}

.overlay {
  @include position-100(absolute);
  background-color: var(--bg);
  z-index: 1;
  opacity: 0;
}

.navigationDrawerInner {
  width: 100%;
  color: var(--fg-invert);
  padding: var(--page-gutter);
  position: relative;
  z-index: 2; // test
}

.navigationDrawerInner__content {
  position: relative;
  z-index: 3;
  height: $navigation-drawer-height;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.bg {
  @include position-100(absolute);
  background-color: var(--bg-invert);
  transform: scaleY(0);
  transform-origin: top;
}

.closeButton {
  @include reset-button;
  @include font-body-6;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}

.top {
  display: grid;
  grid-template-columns: #{px(449)} 1fr;
  gap: px(106);
}

.description__title {
  @include font-body-mono-2;
  opacity: 0.5;
  margin-bottom: px(20);
}

.description__description {
  @include font-body-3;
  opacity: 0;
}

.finishesListTitle {
  @include font-body-mono-2;
  opacity: 0.5;
  margin-bottom: px(5);
}

.finishesList__button {
  @include reset-button;
  @include font-body-4;
  cursor: default;
}

.bottom {
  display: grid;
  grid-template-columns: #{px(240)} #{px(185)} #{px(587)} 1fr;
  align-items: flex-end;
}

.aboutTitle {
  @include font-body-4;
  white-space: pre-line;
}

.locationTitle {
  @include font-body-4;
  white-space: pre-line;
}

.contactTitle {
  @include font-body-4;
  white-space: pre-line;

  span {
    display: block;
  }
}

.socialLinks__link {
  @include flex-center;
  display: inline-flex;
  gap: px(4);
}

.socialLinks__linkLabel {
  @include font-body-6;
}

.socialLinks__arrow {
  width: px(6);
}
