@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Wipe {
  @include position-100(fixed);
  @include z-index(wipe);
  pointer-events: none;
  color: var(--fg-invert);
}

.wipeBg {
  @include position-100(absolute);
  background-color: var(--bg-invert);
  clip-path: polygon(0% var(--left-y), 100% var(--right-y), 100% 100%, 0% 100%);

  --left-y: 100%;
  --right-y: 100%;
}

.titleRight,
.titleLeft {
  @include font-body-6;
  font-weight: 500;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.titleLeft {
  left: var(--page-gutter);
}

.titleRight {
  right: var(--page-gutter);
}

.titleLeft__inner,
.titleRight__inner {
  white-space: pre-line;
  display: inline-block;
  transform: translateY(#{px(40)});
  opacity: 0;
}
