@import "@/styles/shared";
.Navigation {
  @include z-index(navigation);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: var(--page-gutter);
  opacity: 0.001;
  transition: opacity 0.6s;

  &.isVisible {
    opacity: 1;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--page-gutter);
  width: 100%;
}

.left {
  @include flex-center;
}

.title {
  @include font-body-6;
  text-transform: uppercase;
  margin-right: px(43);

  @include bp(tablet) {
    margin-right: px(57);
  }
}

.clock {
  display: none;

  @include bp(tablet) {
    display: block;
    margin-right: px(37);
  }
}

.percentScrolled {
  @include font-body-6;
}

.right {
  @include flex-center;
  gap: px(9);

  @include bp(tablet) {
    gap: px(25);
  }
}

.link {
  @include font-body-6;
  @include flex-center;
  display: none;
  gap: px(5);
  transition: color $theme-transition-duration;

  @include bp(tablet) {
    display: flex;
  }
}

.link__number {
  font-family: $secondary-fonts;
  opacity: 0.5;
  display: none;

  @include bp(tablet) {
    display: inline-block;
  }
}

.drawerButton {
  @include reset-button;
  @include font-body-6;
}

.hoveredProduct {
  @include flex-center;
  gap: px(10);
  position: absolute;
  left: 40%;

  span {
    @include font-body-mono;
    opacity: 0.5;
  }
}
