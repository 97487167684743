@import "@/styles/shared";
// Global styling, normalize overwritten
@import 'fonts';
@import 'shared';

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* stylelint-disable-next-line */
:root {
  // Grid
  --gutter: #{$gutter};
  --page-gutter: #{$page-gutter-mobile};

  @include bp(tablet) {
    --page-gutter: #{$page-gutter};
  }

  // General
  --section-spacing: #{$section-spacing-mobile};

  @include bp(tablet) {
    --section-spacing: #{$section-spacing-desktop};
  }

  // Product grid
  --product-grid-header-padding: #{px(60)};

  @include bp(tablet) {
    --product-grid-header-padding: #{px(85)};
  }

  // Next item
  --next-item-height: #{px(169)};

  @include bp(tablet) {
    --next-item-height: #{px(415)};
  }
}

html {
  @include hide-scrollbar;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: initial;
  min-height: 100%;
  height: auto;
  font-size: 10px;
}

body {
  @include hide-scrollbar;
  @include light-theme;
  font-family: $body-fonts;
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-size: px(16);
  height: auto;
  color: var(--fg);
  background-color: var(--bg);

  // For lenis
  &[data-is-body-scroller='false'] {
    @include position-100(fixed);
    @include vh(100);
    overflow: hidden;
  }

  @media (prefers-color-scheme: dark) {
    @include dark-theme;
  }

  @media (prefers-color-scheme: light) {
    @include light-theme;
  }

  // Theming
  &[data-theme='light'] {
    @include light-theme;
  }

  &[data-theme='dark'] {
    @include dark-theme;
  }

  // Disabling interaction
  &[data-enable-interaction='false'] {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }
}

main {
  @include z-index(main);
  min-height: 100svh;
  position: relative;

  [data-browser='chrome'][data-device='mobile'] & {
    min-height: 100vh; // fix for android/chrome browsers
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

// ====================================
// Lenis
// ====================================
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/* stylelint-disable-next-line selector-id-pattern */
#titleHack {
  [data-show-main-content='true'] & {
    display: none !important;
  }
}
