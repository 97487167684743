@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Clock {
  @include flex-center;
  @include font-body-6;
  gap: px(10);
}

.time {
  font-family: $secondary-fonts;
  opacity: 0.5;
}
