@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ImageReveal {
  position: relative;
  overflow: hidden;
}

.backgroundColor {
  @include position-100(absolute);
  background-color: var(--image-bg);

  .hasBackgroundColor & {
    background-color: var(--background-color);
    opacity: 0.5;
  }
}

.imageContainer {
  height: 100%;
  width: 100%;
  position: relative;
  opacity: 0.001;
  z-index: 2;

  .showContent & {
    opacity: 1;
  }
}
