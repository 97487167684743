@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductGridHeader {
  @include z-index(product-grid-title-count);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.9s;

  &.allowVisual {
    opacity: 1;
  }
}

.inner {
  padding: var(--product-grid-header-padding) var(--page-gutter) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title,
.count {
  @include font-h1;
}

.title {
  transform-origin: left;
}

.count {
  transform-origin: right;
}

.backToTopButton {
  @include reset-button;
  @include font-body-mono-2;
  @include z-index(product-grid-back-to-top-button);
  text-decoration: underline;
  position: fixed;
  bottom: var(--page-gutter);
  right: var(--page-gutter);
}
