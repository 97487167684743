@import "@/styles/shared";
.LogoWithProductsItem {
  position: relative;

  @include bp(tablet, true) {
    width: 100% !important;
  }

  &[data-index='0'] {
    width: px(530);
  }

  &[data-index='1'] {
    width: px(689);
  }

  &[data-index='2'] {
    width: px(827);
  }

  &[data-index='3'] {
    width: px(253);
  }
}

.imageContainer {
  width: 100%;
  aspect-ratio: var(--aspect-ratio);
  position: relative;
}

.image {
  @include position-100(absolute);
  object-fit: cover;
  opacity: 1 !important;
}

.textContainer {
  margin-top: px(10);
  width: 100%;

  @include bp(tablet) {
    margin-top: px(18);
  }
}

.textContainerInner {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  @include font-body-5;
}
