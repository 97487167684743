@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MobileNav {
  @include position-100(fixed);
  @include z-index(mobile-navigation);
  height: 100svh;
  color: var(--fg-invert);
  pointer-events: none;

  &.navIsOpen {
    pointer-events: all;
  }
}

.bgContainer {
  @include position-100(absolute);
  padding: var(--page-gutter);
  background-color: var(--bg-invert);
  clip-path: polygon(0% 0%, 100% 0%, 100% var(--left-y), 0% var(--right-y));

  --left-y: 0%;
  --right-y: 0%;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  @include font-body-6;
  text-transform: uppercase;
}

.topRight {
  @include flex-center;
  gap: px(40);
}

.close {
  @include reset-button;
  @include flex-center;
  position: relative;
}

.close__text {
  @include font-body-6;
}

.close__line {
  top: 100%;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--fg-invert);
  position: absolute;
  pointer-events: none;
}

.navigationContainer {
  margin-top: px(85);
  position: relative;
  z-index: 2;
}

.navigationContainer__titleContainer {
  margin-bottom: px(10);
}

.navigationContainer__title {
  @include font-body-6;
  opacity: 0.5;
  display: block;
}

.informationButton {
  @include reset-button;
  width: 100%;
}

.linkList__link,
.informationButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: px(10) 0;
}

.linkList__link__line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--fg-invert);
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;

  &.last {
    bottom: 0;
    top: auto;
  }
}

.linkList__link__label,
.informationButton__label {
  @include font-navigation-mobile;
  opacity: 0.4;
  transition: opacity 0.3s;

  .isActive & {
    opacity: 1;
  }
}

.linkList__link__number,
.informationButton__number {
  @include font-navigation-mobile;
  opacity: 0.4;
  transition: opacity 0.3s;

  .isActive & {
    opacity: 1;
  }
}

.descriptionContainer {
  @include gpu;
  position: relative;
  overflow: hidden;
  height: 0;
}

.descriptionContainer__inner {
  padding: px(25) 0 px(12);
}

.descriptionContainer__title {
  @include font-body-mono;
  opacity: 0.5;
}

.descriptionContainer__description {
  @include gpu;
  @include font-body-6;
  width: px(288);
  line-height: 1.5;
  margin-top: px(10);
  opacity: 0;
}

.descriptionLine {
  bottom: 0;
  top: auto;
}

.bottomContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: var(--page-gutter);
}

.socialLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--page-gutter);
  margin-bottom: px(15);
}

.socialLinks__link {
  @include flex-center;
  gap: px(5);
}

.socialLinks__linkLabel {
  @include font-body-6;
}

.socialLinks__arrow {
  width: px(6);
}

.bottomTitles {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.aboutTitle {
  @include font-body-4;
  white-space: pre-line;
}

.locationTitle {
  @include font-body-4;
  white-space: pre-line;
}
